<template>
  <div class="flex flex-col md:flex-row md:gap-[20px] h-[310px]">
    <div class="relative flex md:block">
      <div class="relative min-w-[80px] h-[80px] md:w-[130px] md:h-[130px]">
        <nuxt-img
          width="130"
          height="130"
          :src="slide.author?.user?.avatar?.path || NO_IMAGE_PATH"
          class="rounded-full object-cover w-[80px] h-[80px] md:w-[130px] md:h-[130px]"
          loading="lazy"
        />
        <div
          class="flex mb-2 max-w-fit items-center p-2 rounded absolute bottom-[-25px] left-[40px] md:left-1/2 -translate-x-1/2"
          :style="{ 'background-color': '#8bcbfd' }"
        >
          <IconsTagsExpert />
        </div>
      </div>
      <div class="block md:hidden ml-5">
        <div class="font-semibold">
          {{ slide.author.firstName }}
        </div>
        <div class="mt-1 text-sm">
          <div v-if="slide.author?.shortDescription" class="flex items-center">
            <IconsSocialsYoutubeGray
              color="#FF0000"
              class="w-4 h-4 mr-2 items-center text-wrap"
            />
            {{ slide.author?.shortDescription }}
          </div>
          <ReviewListHorizontalHomeExpertProductItem
            :variant="slide?.productVariant"
          />
        </div>
      </div>
    </div>
    <div class="flex flex-col flex-1 text-xs">
      <div class="gap-2.5 hidden md:flex">
        <span class="font-semibold">{{ slide.author.firstName }}</span>
        <span class="ml-auto">{{ slide?.getDate() }}</span>
      </div>
      <div class="hidden md:block mt-2.5 md:mt-1">
        <div
          v-if="slide.author?.shortDescription"
          class="flex items-center mr-2 text-wrap"
        >
          <IconsSocialsYoutubeGray
            color="#FF0000"
            class="w-4 h-4 mr-2 items-center"
          />
          {{ slide.author?.shortDescription }}
        </div>
        <ReviewListHorizontalHomeExpertProductItem
          :variant="slide?.productVariant"
        />
      </div>
      <app-expandable-section
        :button-text="$t('Детальніше')"
        :button-link="
          ProductVariantEntity.buildProductLink(
            slide?.productVariant,
            slide?.productVariant,
            '#expert-reviews',
            locale,
            false
          )
        "
        button-wrapper-class="flex justify-start"
        button-class="underline-offset-[3px]"
        expandable
        :show-chevron="false"
        gradient="to-transparent"
        :collapsed-hight="65"
      >
        <div class="flex flex-col gap-2.5 mt-0 2xs:mt-0 min-h-[66px]">
          <div
            class="line-clamp-4"
            v-html="slide?.textWithLink(slide?.comment)"
          ></div>
          <div v-if="slide?.pros" class="flex">
            <div class="font-semibold mr-2 text-green-ibis">
              {{ $t('Переваги') }}:
            </div>
            <div v-html="slide?.textWithLink(slide?.pros)"></div>
          </div>
          <div v-if="slide?.cons" class="flex">
            <div class="font-semibold mr-2 text-orange">
              {{ $t('Недоліки') }}:
            </div>
            &nbsp;
            <div v-html="slide?.textWithLink(slide?.cons)"></div>
          </div>
        </div>
      </app-expandable-section>
      <div class="flex flex-col gap-2.5">
        <div
          v-if="slide?.youtube || slide?.hasMedia()"
          class="mask-gradient flex justify-start gap-1.5 mt-3 mb-2 flex-wrap relative h-[60px]"
        >
          <div class="absolute h-[60px] bottom-0 left-0 flex gap-x-2">
            <ListExpertsReviewMedia :slide="slide" class="z-1" />
          </div>
        </div>
        <div
          class="flex justify-between items-center md:justify-start"
          :class="!slide?.youtube && !slide?.hasMedia() && 'mt-4'"
        >
          <div class="flex gap-2.5">
            <a
              type="button"
              class="flex gap-1 items-center cursor-pointer"
              :class="
                slide?.reacted === 'like' ? 'text-green-ibis' : 'text-gray-mud'
              "
              @click="emit('toggleReaction', 'like', slide?.id)"
            >
              <IconsReactionLike class="h-3.5 w-3.5" />
              <span>{{ slide?.totalReactions?.like }}</span>
            </a>
            <a
              type="button"
              class="flex gap-1 items-center cursor-pointer"
              :class="
                slide?.reacted === 'dislike' ? 'text-red-hot' : 'text-gray-mud'
              "
              @click="emit('toggleReaction', 'dislike', slide?.id)"
            >
              <IconsReactionDislike class="h-3.5 w-3.5" />
              <span> {{ slide?.totalReactions?.dislike }} </span>
            </a>
          </div>
          <span class="ml-auto block md:hidden">{{ slide?.getDate() }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import ProductVariantEntity from '~/entities/ProductVariant.entity'

defineProps({
  slide: {
    type: Object,
    default: () => ({})
  }
})

const { locale } = useI18n()

const emit = defineEmits(['toggleReaction'])
</script>

<style scoped>
.mask-gradient {
  -webkit-mask-image: linear-gradient(
    to right,
    rgba(0, 0, 0, 1) 80%,
    rgba(0, 0, 0, 0)
  );
  mask-image: linear-gradient(to right, rgba(0, 0, 0, 1) 80%, rgba(0, 0, 0, 0));
}
</style>
