<template>
  <nuxt-link
    :to="
      ProductVariantEntity.buildProductLink(
        variant,
        variant,
        '#expert-reviews',
        locale,
        false
      )
    "
    class="flex bg-white h-[45px] mt-[10px] px-[5px] border border-orange rounded-md"
  >
    <div class="flex items-center justify-center w-[50px] min-w-[50px]">
      <nuxt-img
        width="40"
        height="35"
        class="w-[40px] h-[35px] max-h-[35px] object-contain"
        :src="variant?.images[0]?.path || NO_IMAGE_PATH"
        :alt="variant?.name"
      />
    </div>
    <div class="flex items-center justify-center ml-0.5" :title="variant?.name">
      <span class="line-clamp-2">{{ variant?.name }}</span>
    </div>
  </nuxt-link>
</template>

<script setup>
import ProductVariantEntity from '~/entities/ProductVariant.entity'

const { locale } = useI18n()
defineProps({
  variant: {
    type: Object,
    default: () => {}
  }
})
</script>
