<template>
  <ListHorizontal
    v-if="experts?.length"
    base-slide-width="465"
    :title="t('home_expert_reviews_title')"
    :sub-title="t('home_expert_reviews_sub_title')"
    class="mt-10"
    :space-between="10"
    :slides="experts"
    slide-class="mb-5 p-[20px] sm:p-[20px] bg-[#D0E9FA]"
    :breakpoints="listExpertReviewsBreakpoints"
    @active-slides-indexes="setActiveSlides"
  >
    <template #default="{ slide }">
      <ListExpertReviewHomeItem
        :slide="slide"
        @toggle-reaction="toggleReaction"
      />
    </template>
  </ListHorizontal>
</template>

<script setup>
import ProductFeedbackEntity from '~/entities/ProductFeedback.entity'
import { useAuthStore } from '~/stores/auth'

const { t } = useI18n()
const authStore = useAuthStore()

const props = defineProps({
  subTitle: {
    type: String,
    default: ''
  },
  titleClass: {
    type: String,
    default: 'text-xl font-bold'
  },
  slidesPerView: {
    type: Number,
    default: 1
  }
})

const reviewsData = ref([])

const filter = ref({
  page: 1,
  itemsPerPage: 10,
  customer: 'expert',
  'order[createdAt]': 'desc'
})

const listExpertReviewsBreakpoints = {
  320: {
    slidesPerView: 1
  },
  850: {
    slidesPerView: 2
  },
  1280: {
    slidesPerView: 3
  }
}

const { data: expertsReviews } = useLazyAsyncData(
  'home-experts-reviews-all',
  () => {
    return useApi().productReviews.getVariantReviews(filter.value)
  },
  {
    transform: res => {
      return ProductFeedbackEntity.parseApiResponse(res)
    }
  }
)

const experts = computed(
  () =>
    (
      expertsReviews.value && [
        ...expertsReviews.value?.data,
        ...reviewsData.value
      ]
    )?.map(item => new ProductFeedbackEntity(item)) || []
)

const activeSlides = ref(null)

const setActiveSlides = slides => {
  activeSlides.value = slides
}

const fetchMoreReviews = () => {
  filter.value.page++
  return useApi().productReviews.getVariantReviews(filter.value, {
    onSuccess: res => {
      const data = ProductFeedbackEntity.parseApiResponse(res)?.data
      if (data?.length) {
        reviewsData.value = [...reviewsData.value, ...data]
      }
    }
  })
}

watch(activeSlides, () => {
  const currentSlide = activeSlides.value?.pop()
  if (
    currentSlide >= experts.value?.length - 1 &&
    expertsReviews.value?.totalItems > experts.value?.length
  ) {
    fetchMoreReviews()
  }
})

const toggleReaction = async (reaction, id) => {
  if (!authStore.loggedIn) {
    return authStore.showAuthDialog(useRoute().fullPath)
  }

  const index = experts.value?.findIndex(review => review.id === id)

  if (index !== -1) {
    const review = experts?.value[index]
    const currentReaction = review.reacted
    if (currentReaction) {
      review.totalReactions[currentReaction]--
    }
    review.reacted = currentReaction === reaction ? '' : reaction
    if (review.reacted) {
      review.totalReactions[review.reacted]++
    }
  }

  await useApi().productReviewLike.toggleReaction({
    id,
    type: reaction
  })
}
</script>
